import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import React, { useEffect } from "react"
// import { renderEmail } from "react-html-email"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ConfirmationTemplate from "../components/template/confirmationTemplate"
import moment from "moment"
import formatPrice from "../utils/priceFormatter"

const Confirmation = () => {
    const location = useLocation()
    const { state } = location

    useEffect(() => {
        if (!state?.data) navigate(-1)
    }, [state])

    const fromTo = state?.data.from
        ? `${moment(state?.data.from).format("Do of MMMM YYYY")} ${
              state?.data.to ? "- " + moment(state?.data.to).format("Do of MMM YYYY") : ""
          }`
        : ""

    // if (state?.data) {
    //     const html = renderEmail(<ConfirmationTemplate
    //         firstName={state.data.firstName}
    //         yachtName={state.data.vesselName}
    //         fromTo={fromTo}
    //         guests={state.data.guests}
    //         routeName={state.data.routeCopy.routeName}
    //         extras={state.data.extrasCopy}
    //         totalAmount={formatPrice(state?.data.totalPrice)}
    //     />)

    //     console.log(html)
    // }

    return state?.data ? (
        <Layout>
            <Seo title="Confirmation" />
            <ConfirmationTemplate
                firstName={state.data.firstName}
                yachtName={state.data.vesselName}
                fromTo={fromTo}
                guests={state.data.guests}
                routeName={state.data.routeCopy.routeName}
                extras={state.data.extrasCopy}
                totalAmount={formatPrice(state.data.totalPrice)}
            />
        </Layout>
    ) : null
}

export default Confirmation
