import React from "react"
import { A, Box, Email, Image, Item, Span } from "react-html-email"
import { isProdLocation } from "../../utils/helpers"

const emailHeadCSS = `
  body {
    background-color: #ffffff;
  }
`.trim()

const containerStyle = {
    backgroundColor: "#DDF3FF",
    width: "100%",
    minHeight: "100vh",
    padding: "80px 50px 40px 50px"
}

const backgroundStyle = {
    backgroundColor: "#FFF",
    margin: "0 auto",
    width: "100%",
    padding: "0 30px",
    position: "relative"
}

const logoStyles = {
    position: "absolute",
    width: "85px",
    height: "98px",
    top: "-48px",
    left: "36%",
    transform: "translate(50%,0)"
}

const titleStyle = {
    fontSize: "30px",
    fontWeight: 400,
    color: "#3D4998",
    fontFamily: "Alkaline, Georgia"
}

const descriptionStyle = {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "150%",
    color: "#616371",
    fontFamily: "Bio-Sans"
}

const getInTouchStyles = {
    color: "#3D4998",
    fontWeight: "bold"
}

const experienceHeaderStyles = {
    backgroundColor: "#3D4998",
    height: "40px",
    margin: "0 30px",
    display: "flex"
}

const experienceSpanStyles = {
    color: "#fff",
    fontFamily: "Alkaline, Georgia",
    letterSpacing: "0.01rem",
    fontSize: "21px",
    fontWeight: 300,
    margin: "auto auto"
}

const tableWrapperStyles = {
    backgroundColor: "#B9E0F61A",
    width: "100%"
}
const tableItemStyles = {
    padding: "0 30px 10px 30px",
    marginBottom: "10px",
    display: "flex"
}

const itemTitleStyles = {
    fontFamily: "Bio-Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#3D4998CC",
    width: "33%"
}

const itemContentStyles = {
    fontFamily: "Bio-Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#616371",
    width: "66%",
    display: "flex",
    flexDirection: "column"
}

const paidRawStyles = {
    padding: "30px 30px",
    display: "flex"
}

const paidTitleStyles = {
    fontFamily: "Bio-Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#3D4998",
    width: "33%"
}

const paidAmountStyles = {
    fontFamily: "Bio-Sans",
    fontSize: "20px",
    fontWeight: 400,
    color: "#3D4998",
    width: "66%"
}

const linksWrapperStyles = {
    textAlign: "center",
    width: "100%"
}

const linksImagesStyles = {
    width: "30px",
    margin: "auto",
    display: "inline-block"
}

const waterStyles = {
    maxWidth: "600px"
}

const cardImageStyles = {
    width: "200px",
    position: "absolute",
    right: "45px",
    bottom: "16px"
}

const TableItem = ({ title, content }) => (
    <Item style={tableItemStyles}>
        <Span style={itemTitleStyles}>{title}</Span>
        <Span style={itemContentStyles}>{content}</Span>
    </Item>
)

const ConfirmationTemplate = ({ firstName, yachtName, fromTo, guests, routeName, extras, totalAmount }) => {
    const webLink = isProdLocation() ? "https://www.maliblueyachts.com" : "http://localhost:8000"

    return (
        <Box style={containerStyle}>
            <Email headCSS={emailHeadCSS} align="center">
                <Item style={{ height: "80px" }} />
                <Item align="center">
                    <Box style={backgroundStyle}>
                        <Image src={`${webLink}/images/logo-2.png`} style={logoStyles} />
                        <Item style={{ height: "86px" }} />
                        <Item style={{ textAlign: "center" }}>
                            <Span style={titleStyle}>Booking Confirmation</Span>
                        </Item>
                        <Item style={{ height: "17px" }} />
                        <Item style={{ textAlign: "center", padding: "0 30px" }}>
                            <Span style={descriptionStyle}>
                                Hi <b>{firstName}</b> thank you for booking your Ibiza Yacht Experience with us. Below you’ll find a
                                summary of your booking. If you have any questions please don’t hesitate to{" "}
                                <A style={getInTouchStyles} href="https://www.maliblueyachts.com/contact">
                                    get in touch
                                </A>
                                .
                            </Span>
                        </Item>
                        <Item style={{ height: "35px" }} />
                        <Item style={experienceHeaderStyles}>
                            <Span style={experienceSpanStyles}>Your Experience details</Span>
                        </Item>
                        <Item style={{ padding: "0 30px" }}>
                            <Box style={tableWrapperStyles}>
                                <Item style={{ height: "35px" }} />
                                <TableItem title="YOUR YACHT" content={yachtName} />
                                <TableItem title="YOUR Date" content={fromTo} />
                                <TableItem title="YOUR Guests" content={`${guests} Guests`} />
                                <TableItem title="YOUR Route" content={routeName} />
                                {extras?.map((e, index) => (
                                    <TableItem key={e.id} title={index === 0 ? "YOUR Extras" : ""} content={e.extraName} />
                                ))}
                                <Item style={{ height: "35px" }} />
                                <Item style={{ height: "2px", backgroundColor: "#3D4998" }} />
                                <Item style={{ height: "30px" }} />
                                <Item style={paidRawStyles}>
                                    <Span style={paidTitleStyles}>Paid by Card</Span>
                                    <Span style={paidAmountStyles}>€{totalAmount}</Span>
                                </Item>
                                <Item style={{ height: "30px" }} />
                                <Item style={{ height: "5px", backgroundColor: "#3D4998" }} />
                            </Box>
                        </Item>
                        <Item style={{ height: "40px" }} />
                        <Item style={linksWrapperStyles}>
                            <A href="https://www.instagram.com/maliblueyachts/">
                                <Image style={linksImagesStyles} src={`${webLink}/images/instagram.png`} />
                            </A>
                            <A href="https://www.facebook.com/Maliblue-Yachts-102827445682541">
                                <Image style={{ ...linksImagesStyles, marginLeft: "25px" }} src={`${webLink}/images/facebook.png`} />
                            </A>
                            <A href="mailto:hello@maliblueyachts.com">
                                <Image style={{ ...linksImagesStyles, marginLeft: "25px" }} src={`${webLink}/images/email.png`} />
                            </A>
                        </Item>
                        <Item style={{ height: "55px" }} />
                        <Image style={cardImageStyles} src={`${webLink}/images/yacht7.png`} />
                        <Image style={waterStyles} src={`${webLink}/images/water.png`} />
                    </Box>
                </Item>
                <Item style={{ height: "30px" }} />
            </Email>
        </Box>
    )
}

export default ConfirmationTemplate
